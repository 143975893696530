<template>
  <div class="my-container">
    <el-form>
      <el-button @click="handleExport">导出Excel</el-button>
    </el-form>
    <div ref="excelContainer" class="excel-container">
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import config from '@/config'
import { mapGetters } from 'vuex'
import moment from 'moment'

const fileSaver = require('file-saver')

export default {
  computed: {
    ...mapGetters('planStatExamination', {
      planStatExaminationGuid: 'getPlanStatExaminationGuid'
    })
  },
  methods: {
    handleExport () {
      if (!this.excelIo) {
        this.excelIo = new GC.Spread.Excel.IO()
      }
      this.excelIo.save(this.workbook.toJSON(), blob => {
        fileSaver.saveAs(blob, `${moment().format('YYYY-MM-DD HH:mm:ss导出')}.xlsx`)
      })
    },
    obj2Array (row) {
      return [
        row.meterageList.meterageName,
        row.meterageList.unit,
        row.meterageList.price,
        row.meterageList.num,
        row.meterageList.amount,
        row.currentDataNum,
        row.totalDateNum,
        row.totalDatePercent
      ]
    },
    async getData () {
      const result = await axios.get(`${config.restHost}/g181`, {
        params: {
          planStatExaminationGuid: this.planStatExaminationGuid,
          pageSize: 10000,
          pageIndex: 1
        }
      })
      this.list = [
        [
          '工程或费用项目名称',
          '单位',
          '单价',
          '合同数量',
          '合同金额',
          '今日完成',
          '累计完成',
          '累计完成占合同(%)'
        ],
        ...result.data.map(row => this.obj2Array(row))
      ]
      console.log(this.list)
    },
    initSpread () {
      if (!this.workbook) {
        this.workbook = new GC.Spread.Sheets.Workbook(this.$refs.excelContainer)
      }
      this.workbook.getActiveSheet().setRowCount(this.list.length)
      this.workbook.getActiveSheet().setColumnCount(this.list[0].length)
      this.workbook.getActiveSheet().setArray(0, 0, this.list)

      for (let i = 0; i < this.list[0].length; ++i) {
        this.workbook.getActiveSheet().autoFitColumn(i)
      }
    }
  },
  async mounted () {
    await this.getData()
    this.initSpread()
  }
}
</script>

<style lang="scss" scoped>
.my-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  & .excel-container {
    margin-top: 10px;
    flex: 1;
    overflow: hidden;
  }
}
</style>
